import React from "react";

function TextFields({textFields, index, solution, setSolution}) {
	return (
		<div className={"flex-fill"}>
			{textFields.map((value) => {
				return (
					<div className="form-group" key={value.id + index}>
						<label htmlFor={"textField" + value.id + index}>{value.name}</label>
						<input type="text" className="form-control" required={value.required} id={"textField" + value.id + index} value={solution[index][value.name]} onChange={(evt) => {
							setSolution((prevState) => {
								const newState = [...prevState];
								newState[index][value.name] = evt.target.value;
								return newState;
							});
						}}/>
					</div>
				);
			})}
		</div>
	);
}

export default TextFields;