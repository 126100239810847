import React from "react";

function Info({message = ""}) {
    return (
        <div className="alert alert-info" role="alert" dangerouslySetInnerHTML={
            {
                __html: message.replace(/\r\n|\r|\n/g, '<br/>')
            }
        } />
    );
}

export default Info;
