import React from "react";
import Booleans from "./Booleans";
import DropDowns from "./DropDowns";
import TextFields from "./TextFields";

function Row({row, columns, booleans,dropDowns, textFields, index, solution, setSolution}) {
	return (
		<div className={"border mb-4 p-2"}>
			<table className={"table table-sm table-light table-striped"}>
				<tbody>
				{row.map((value, index) => {
					return (
						<tr key={index}>
							<th style={{width: '10%'}}>{columns[index]}:</th>
							<td>{value}</td>
						</tr>
					);
				})}
				</tbody>
			</table>


			<div  className={"d-flex "}>
				<Booleans booleans={booleans} index={index} solution={solution} setSolution={setSolution}/>
				<DropDowns dropDowns={dropDowns} index={index} solution={solution} setSolution={setSolution}/>
				<TextFields textFields={textFields} index={index} solution={solution} setSolution={setSolution}/>
			</div>
		</div>
	);
}

export default Row