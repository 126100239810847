import React, {useEffect, useState} from "react";
import {useParams, useHistory} from "react-router-dom";
import Info from "./Info";
import {getTest} from "../models/tests";
import Row from "./Row";

function Test({answers, setAnswers, onSubmit}) {
    const [test, setTest] = useState({});
    const {testId} = useParams();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const response = await getTest(testId);

                if (response.errors <= 0) {
                    if (!response.test) {
                        history.push("/");
                    }
                    else {
                        setTest(response.test);
                        const {booleans, dropDowns, textFields, data} = response.test;

                        const object = {};

                        for (let {name} of booleans) {
                            object[name] = false;
                        }
                        for (let {name, multiple} of dropDowns) {
                            if (multiple) {
                                object[name] = [];
                            }
                            else {
                                object[name] = "";
                            }
                        }
                        for (let {name} of textFields) {
                            object[name] = "";
                        }

                        setAnswers(new Array(data.length).fill(null).map(value => {
                            return {...object};
                        }));
                        console.log(data);
                    }
                }
            }
            catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return (
        <div className="tab-pane fade" id="test" role="tabpanel" aria-labelledby="test-tab">
            <h3>{test.name}</h3>
            <Info message={test.instruction}/>
            <form onSubmit={onSubmit}>
                {(test.data && answers.length > 0) &&
                    test.data.map((value, index) => {
                        return <Row solution={answers} setSolution={setAnswers} columns={test.columns} row={value} booleans={test.booleans} dropDowns={test.dropDowns} textFields={test.textFields} index={index} key={index}/>
                    })
                }

                <div className="text-right">
                    <button type={"submit"} className={"btn btn-success"}>Submit</button>
                </div>
            </form>
        </div>
    );
}

export default Test;