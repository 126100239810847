import React, {useState} from "react";
import BasicInfo from "./components/BasicInfo";
import Test from "./components/Test";
import {BrowserRouter as Router, Switch, Route, Redirect, useLocation, useHistory} from "react-router-dom";
import {submit} from "./models/questions";
import Found from "./components/Found";
import NotFound from "./components/NotFound";
import Footer from "./components/Footer";

function App() {


    return (
        <Router>
            <Switch>
                <Route path={'/:testId'} exact={true}>
                    <Found />
                </Route>
                <Route path={'*'}>
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    );

}

export default App;