import React from "react";

function DropDowns({dropDowns, index, solution, setSolution}) {
	return (
		<div className={"flex-fill mx-2"}>
			{dropDowns.map((value) => {
				return (
					<div className="form-group" key={value.id + index}>
						<label htmlFor={"dropDown" + value.id + index}>{value.name}</label>
						<select multiple={value.multiple} className={"form-control"} id={"dropDown" + value.id + index} required={true} value={solution[index][value.name]} onChange={(evt) => {
							if (value.multiple) {
								setSolution(prevState => {
									const newState = [...prevState];
									newState[index][value.name] = Array.from(evt.target.selectedOptions, option => option.value);
									return newState;
								});
							}
							else {
								setSolution(prevState => {
									const newState = [...prevState];
									newState[index][value.name] = evt.target.value;
									return newState;
								});
							}
						}}>
							{!value.multiple && <option value={""}>Select an option</option>}
							{value.options.map(option => {
								return <option key={option.id + index} id={option.id + index} value={option.name}>{option.name}</option>;
							})}
						</select>
					</div>
				);
			})}
		</div>
	);
}

export default DropDowns;