import React, {useState} from "react";
import BasicInfo from "./BasicInfo";
import Test from "./Test";
import {submit} from "../models/questions";
import {useParams} from "react-router-dom";
import Error from "./Error";

function Found(){
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        professionalProfile: "",
        languagePairs: [],
        motherTongue: ""
    });
    const [answers, setAnswers] = useState({});
    const [nextDisabled, setNextDisabled] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState([]);

    const {testId} = useParams();

    async function onSubmit(evt) {
        evt.preventDefault();
        try {
            const response = await submit(testId, answers, state);
            if (response.errors.length > 0) {
                setErrors(response.errors);
            }
            else {
                setSubmitted(true);
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    if (submitted) {
        return (
            <h1>Thank you for submitting this form. The Datamundi project managers will inform you when we have suitable projects for your profile.</h1>
        )
    }

    return (
        <div className="container overflow-hidden">
            <div className=" shadow-sm bg-white mt-2 ">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab"
                           aria-controls="info" aria-selected="true">Info</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (nextDisabled ? "disabled": "")} id="test-tab" data-toggle="tab" href="#test" role="tab"
                           aria-controls="test" aria-selected="false">Test</a>
                    </li>
                </ul>

                <div className={"mt-2"}>
                    {errors.map((error, index) => <Error key={index} message={error} />)}

                </div>

                <div className="tab-content p-2 border" id="tabContent">
                    <BasicInfo setErrors={setErrors} state={state} setState={setState} nextDisabled={nextDisabled} setNextDisabled={setNextDisabled}/>
                    <Test answers={answers} setAnswers={setAnswers} onSubmit={onSubmit}/>
                </div>
            </div>
        </div>
    )
}

export default Found;