import React from "react";

function Booleans({booleans, index, solution, setSolution}) {
	return (
		<div className={"flex-fill"}>
			{booleans.map((value) => {
				return (
					<div className="form-group form-check" key={value.id + index}>
						<input type="checkbox" className="form-check-input" id={"boolean" + value.id + index} checked={solution[index][value.name]} onChange={(evt) => {
							setSolution((prevState) => {
								const newState = [...prevState];
								newState[index][value.name] = evt.target.checked;
								return newState;
							});
						}}/>
						<label className="form-check-label" htmlFor={"boolean" + value.id + index}>{value.name}</label>
					</div>
				);
			})}
		</div>
	);
}

export default Booleans;