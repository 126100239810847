const endPoint = process.env.REACT_APP_backend || "http://localhost:1555";

export async function submit(testId, answers, basicInfo) {
    const response = await fetch(endPoint + "/" + testId, {
        method: "POST",
        body: JSON.stringify({
            answers,
            basicInfo
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.json();
}

