import React, {useEffect, useState} from "react";
import {getLanguagePairs} from "../models/languagePairs";
import {getLanguages} from "../models/languages";

function BasicInfo({setErrors, state: {firstName, lastName, email, professionalProfile, languagePairs, motherTongue}, setState, nextDisabled, setNextDisabled}) {
    const [languagePairRows, setLanguagePairRows] = useState([]);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const responseLPairs = await getLanguagePairs();
                if (responseLPairs.errors <= 0) {
                    setLanguagePairRows(responseLPairs.languagePairs);
                }
                const responseLanguages = await getLanguages();
                if (responseLanguages.errors <= 0) {
                    setLanguages(responseLanguages.languages);
                }
            }
            catch (e) {
                console.error(e);
            }
        })();
    }, []);

    useEffect(() => {
        if (document.getElementById("infoForm").checkValidity()) {
            setNextDisabled(false);
        }
        else {
            setNextDisabled(true);
        }
    }, [firstName, lastName, email, professionalProfile, languagePairs, motherTongue]);

    function onSubmit(evt) {
        evt.preventDefault();
        if (email.endsWith("yahoo.com")) {
            setErrors(["Please enter a different email address (not yahoo) in order to ensure you receive the automated notifications correctly."]);
        }
        else {
            setErrors([]);
            document.getElementById("test-tab").click();
        }
    }

    return (
        <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
            <form id="infoForm" onSubmit={onSubmit}>
                <div className="form-row">
                    <div className="form-group col">
                        <label htmlFor="firstName">First name:</label>
                        <input type="text" id="firstName" value={firstName} onChange={(evt) => {
                            setState((prevState) => {
                                const newState = {...prevState};
                                newState.firstName = evt.target.value;
                                return newState;
                            });
                        }} className="form-control"
                               placeholder="First name" required />
                    </div>
                    <div className="form-group col">
                        <label htmlFor="lastName">Last name:</label>
                        <input type="text" id="lastName" value={lastName} onChange={(evt) => {
                            setState((prevState) => {
                                const newState = {...prevState};
                                newState.lastName = evt.target.value;
                                return newState;
                            });
                        }} className="form-control"
                               placeholder="Last name" required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col">
                        <label htmlFor="email">Email address:</label>
                        <input type="email" id="email" value={email} onChange={(evt) => {
                            setState((prevState) => {
                                const newState = {...prevState};
                                newState.email = evt.target.value;
                                return newState;
                            });
                        }} className="form-control"
                               placeholder="Email address" required />
                    </div>
                    <div className="form-group col">
                        <label htmlFor="professionalProfile">Your professional profile (LinkedIn, Proz,
                            etc):</label>
                        <input type="text" id="professionalProfile" value={professionalProfile} onChange={(evt) => {
                            setState(prevState => {
                                const newState = {...prevState};
                                newState.professionalProfile = evt.target.value;
                                return newState;
                            });
                        }} className="form-control"
                               placeholder="Your professional profile (LinkedIn, Proz, etc)"  />
                    </div>
                </div>
                <div className="form-group ">
                    <label htmlFor="languagePairs" > Language pairs you apply for:</label>
                    <select id="languagePairs" onChange={(evt) => {
                        setState((prevState) => {
                            const newState = {...prevState};
                            newState.languagePairs = Array.from(evt.target.selectedOptions, option => option.value);
                            return newState;
                        });
                    }} value={languagePairs} multiple={true} className="form-control basicInfo" required>
                        {languagePairRows.map((value, index) => {
                            return <option value={value.id} key={value.id}>{value.source} -> {value.target}</option>
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="motherTongue">Mother tongue:</label>
                    <select id="motherTongue" required={true} value={motherTongue} className={"form-control"} onChange={(evt) => {
                        setState((prevState) => {
                            const newState = {...prevState};
                            newState.motherTongue = evt.target.value;
                            return newState;
                        });
                    }}>
                        <option value="">Select an option</option>
                        {languages.map((value, index) => {
                            return <option value={value.id} key={value.id}>{value.name}</option>
                        })}
                    </select>
                </div>

                <div className="text-right">
                    <button type="submit" className="btn btn-primary"  id="nextBtn">Next
                        -&gt;</button>
                </div>
            </form>
        </div>
    );
}

export default BasicInfo;